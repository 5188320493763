<template>
  <el-form
    :model="form"
    :rules="rules"
    ref="productForm"
    v-loading="loading"
  >
    <content-card class="margin-bottom-24">
      <div slot="title" class="w-full inline-flex justify-between margin-bottom-24">
        <strong>Categoría</strong>
        <a class="text-base text-purple-400" @click="showCategoryCreate">
          <span>+ Agregar nueva categoria</span>
        </a>
      </div>
      <div class="w-full inline-flex">
        <el-form-item prop="category" class="w-full padding-right-8">
          <el-input
            type="text"
            v-model="textSearch"
            @input="showCategorySearch(true)">
            <i slot="prefix" class='mt-2 text-xl bx bx-search' />
          </el-input>
        </el-form-item>
      </div>
    </content-card>
    <content-card class="margin-bottom-24" title="Producto">
      <el-form-item prop="name">
        <strong>Nombre</strong>
        <el-input v-model="form.name"/>
      </el-form-item>
      <el-form-item prop="description">
        <strong>Descripción</strong>
        <el-input type="textarea" :rows="3" v-model="form.description"/>
      </el-form-item>
    </content-card>
    <content-card class="margin-bottom-24" title="Precio">
      <div class="w-full inline-flex">
        <el-form-item prop="cost" class="w-full padding-right-8" v-if="$store.state.account.canEditOrdersPrices">
          <span class="inline-flex items-center justify-center flex-shrink-0">
            Costo
          </span>
          <el-input type="number" v-model="form.cost" />
        </el-form-item>
        <el-form-item prop="price" class="w-full padding-left-8">
          <span class="inline-flex items-center justify-center flex-shrink-0">
            Precio de venta
          </span>
          <el-input type="number" v-model="form.price" />
        </el-form-item>
      </div>
    </content-card>
    <content-card class="margin-bottom-24" title="Inventario" v-if="$store.state.account.canEditStockQuantities">
      <div class="w-full inline-flex">
        <el-form-item prop="quantity" class="w-1/2 padding-right-8">
          <span class="inline-flex items-center justify-center flex-shrink-0">
            Existencias
          </span>
          <el-input type="number" v-model="form.quantity" :disabled="!$store.state.account.canEditStockQuantities" />
        </el-form-item>
      </div>
    </content-card>
    <el-dialog
      :visible.sync="visibleCategorySearch"
      :width="width"
      append-to-body>
      <div>
        <div class="w-full flex flex-row px-6 py-4">
          <el-input v-model="textSearch" ref="textSearch">
            <i slot="prefix" class="mt-2 text-xl bx bx-search" />
          </el-input>
        </div>
        <table>
          <thead>
            <tr>
              <th>Nombre</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="category in categories" :key="category.id">
              <td>
                <el-checkbox :value="selectedCategory === category.id" @change="chooseCategory(category.id)">
                  {{ category.name }}
                </el-checkbox>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="flex flex-row justify-end space-x-2">
          <el-button
            class="el-button--default"
            @click="showCategorySearch(false)">
            Cancelar
          </el-button>
          <el-button
            class="el-button--primary"
            :disabled="!selectedCategory"
            @click="selectCategory()">
            Seleccionar
          </el-button>
        </div>
      </div>
    </el-dialog>
  </el-form>
</template>
<style scoped>
.upload-button {
  background: white;
  border: 1px dashed #c0c4c3;
  color: #c0c4c3;
  width: 4rem;
  height: 4rem;
}

.upload-button:hover {
  border: 1px dashed black;
  color: black;
}

.upload-button:disabled {
  border: 1px dashed #c0c4c3;
  color: #c0c4c3;
}

.margin-bottom-24 {
  margin-bottom: 24px;
}

.padding-left-8 {
  padding-left: 8px;
}

.padding-right-8 {
  padding-right: 8px;
}
</style>
<script>
import CategoryDialogService, { command as CategoryDialogCommand, commands } from '@/components/dialogs/Category/service';
import CategoryEnum from '@/components/dialogs/Category/enum';
import CategoryService from '@/services/CategoryService';
import _ from 'lodash';
import error from '@/mixins/error';
import responsiveSize from '@/mixins/responsiveSize';

export default {
  name: 'ProductForm',
  props: {
    loading: Boolean,
    form: {
      name: String,
      description: String,
      category: Object,
      brand_model: String,
      cost: Number,
      price: Number,
      quantity: Number,
      image: String,
    },
  },
  mixins: [error, responsiveSize],
  components: {
    ContentCard: () => import('@/components/molecules/ContentCard'),
  },
  data() {
    return {
      CategoryEnum,
      errorMessage: undefined,
      show: false,
      rules: {},
      visibleCategorySearch: false,
      categories: [],
      width: '40%',
      textSearch: null,
      selectedCategory: null,
      subscription: null,
    };
  },
  created() {
    this.subscription = CategoryDialogCommand.subscribe(this.customCommands);
    window.addEventListener('resize', _.debounce(this.resize, 300));
  },
  destroyed() {
    this.subscription.unsubscribe();
    window.removeEventListener('resize', _.debounce(this.resize, 300));
  },
  mounted() {
    setTimeout(() => {
      this.textSearch = this.$props.form.category?.name;
    }, 1000);
  },
  watch: {},
  methods: {
    customCommands({ type, payload }) {
      switch (type) {
        case commands.create:
          this.categoryCreate(payload);
          break;
        default:
      }
    },
    resize() {
      const percentage = this.getPercentageWidth();
      this.width = percentage === '30%' ? '40%' : percentage;
    },
    showCategoryCreate() {
      CategoryDialogService.show({ type: CategoryEnum.Product });
    },
    categoryCreate(category) {
      this.form.category = category.id;
      this.textSearch = category.name;
    },
    chooseCategory(categoryId) {
      this.selectedCategory = this.selectedCategory === categoryId ? null : categoryId;
    },
    selectCategory() {
      if (!this.selectedCategory) return;
      const category = this.categories.find((c) => c.id === this.selectedCategory);
      this.form.category = category.id;
      this.textSearch = category.name;
      this.visibleCategorySearch = false;
      this.categories = [];
    },
    categoryChange(category) {
      this.form.category = category;
      this.form.brand_model = undefined;
    },
    showCategorySearch(value) {
      this.visibleCategorySearch = value;
      if (value) this.loadCategories();
      if (!value) this.textSearch = null;
    },
    async loadCategories() {
      try {
        this.categories = await CategoryService.Product.get({ textSearch: this.textSearch });
      } catch (e) {
        this.errorMessage = e;
      }
    },
  },
}
</script>
